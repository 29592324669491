exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-actie-royal-canin-tsx": () => import("./../../../src/pages/actie-royal-canin.tsx" /* webpackChunkName: "component---src-pages-actie-royal-canin-tsx" */),
  "component---src-pages-algemene-voorwaarden-tsx": () => import("./../../../src/pages/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-tsx" */),
  "component---src-pages-consultaties-hond-tsx": () => import("./../../../src/pages/consultaties/hond.tsx" /* webpackChunkName: "component---src-pages-consultaties-hond-tsx" */),
  "component---src-pages-consultaties-kat-tsx": () => import("./../../../src/pages/consultaties/kat.tsx" /* webpackChunkName: "component---src-pages-consultaties-kat-tsx" */),
  "component---src-pages-consultaties-kip-en-vogels-tsx": () => import("./../../../src/pages/consultaties/kip-en-vogels.tsx" /* webpackChunkName: "component---src-pages-consultaties-kip-en-vogels-tsx" */),
  "component---src-pages-consultaties-konijn-tsx": () => import("./../../../src/pages/consultaties/konijn.tsx" /* webpackChunkName: "component---src-pages-consultaties-konijn-tsx" */),
  "component---src-pages-consultaties-tsx": () => import("./../../../src/pages/consultaties.tsx" /* webpackChunkName: "component---src-pages-consultaties-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-diensten-bloeddrukmeting-tsx": () => import("./../../../src/pages/diensten/bloeddrukmeting.tsx" /* webpackChunkName: "component---src-pages-diensten-bloeddrukmeting-tsx" */),
  "component---src-pages-diensten-castratie-en-sterilisatie-tsx": () => import("./../../../src/pages/diensten/castratie-en-sterilisatie.tsx" /* webpackChunkName: "component---src-pages-diensten-castratie-en-sterilisatie-tsx" */),
  "component---src-pages-diensten-chirurgie-tsx": () => import("./../../../src/pages/diensten/chirurgie.tsx" /* webpackChunkName: "component---src-pages-diensten-chirurgie-tsx" */),
  "component---src-pages-diensten-echografie-tsx": () => import("./../../../src/pages/diensten/echografie.tsx" /* webpackChunkName: "component---src-pages-diensten-echografie-tsx" */),
  "component---src-pages-diensten-eigen-labo-tsx": () => import("./../../../src/pages/diensten/eigen-labo.tsx" /* webpackChunkName: "component---src-pages-diensten-eigen-labo-tsx" */),
  "component---src-pages-diensten-gedragsadvies-tsx": () => import("./../../../src/pages/diensten/gedragsadvies.tsx" /* webpackChunkName: "component---src-pages-diensten-gedragsadvies-tsx" */),
  "component---src-pages-diensten-hospitalisatie-tsx": () => import("./../../../src/pages/diensten/hospitalisatie.tsx" /* webpackChunkName: "component---src-pages-diensten-hospitalisatie-tsx" */),
  "component---src-pages-diensten-microscopie-tsx": () => import("./../../../src/pages/diensten/microscopie.tsx" /* webpackChunkName: "component---src-pages-diensten-microscopie-tsx" */),
  "component---src-pages-diensten-radiografie-tsx": () => import("./../../../src/pages/diensten/radiografie.tsx" /* webpackChunkName: "component---src-pages-diensten-radiografie-tsx" */),
  "component---src-pages-diensten-tandbehandeling-tsx": () => import("./../../../src/pages/diensten/tandbehandeling.tsx" /* webpackChunkName: "component---src-pages-diensten-tandbehandeling-tsx" */),
  "component---src-pages-diensten-tsx": () => import("./../../../src/pages/diensten.tsx" /* webpackChunkName: "component---src-pages-diensten-tsx" */),
  "component---src-pages-diensten-vaccinatie-tsx": () => import("./../../../src/pages/diensten/vaccinatie.tsx" /* webpackChunkName: "component---src-pages-diensten-vaccinatie-tsx" */),
  "component---src-pages-diensten-voedingsadvies-tsx": () => import("./../../../src/pages/diensten/voedingsadvies.tsx" /* webpackChunkName: "component---src-pages-diensten-voedingsadvies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ons-team-erinke-van-der-hooft-tsx": () => import("./../../../src/pages/ons-team/erinke-van-der-hooft.tsx" /* webpackChunkName: "component---src-pages-ons-team-erinke-van-der-hooft-tsx" */),
  "component---src-pages-ons-team-evelien-deleersnyder-tsx": () => import("./../../../src/pages/ons-team/evelien-deleersnyder.tsx" /* webpackChunkName: "component---src-pages-ons-team-evelien-deleersnyder-tsx" */),
  "component---src-pages-ons-team-jasmine-kestelijn-tsx": () => import("./../../../src/pages/ons-team/jasmine-kestelijn.tsx" /* webpackChunkName: "component---src-pages-ons-team-jasmine-kestelijn-tsx" */),
  "component---src-pages-ons-team-laura-vantilborgh-tsx": () => import("./../../../src/pages/ons-team/laura-vantilborgh.tsx" /* webpackChunkName: "component---src-pages-ons-team-laura-vantilborgh-tsx" */),
  "component---src-pages-ons-team-magaline-verheyden-tsx": () => import("./../../../src/pages/ons-team/magaline-verheyden.tsx" /* webpackChunkName: "component---src-pages-ons-team-magaline-verheyden-tsx" */),
  "component---src-pages-ons-team-sally-vanmeulen-tsx": () => import("./../../../src/pages/ons-team/sally-vanmeulen.tsx" /* webpackChunkName: "component---src-pages-ons-team-sally-vanmeulen-tsx" */),
  "component---src-pages-ons-team-tsx": () => import("./../../../src/pages/ons-team.tsx" /* webpackChunkName: "component---src-pages-ons-team-tsx" */),
  "component---src-pages-prijzen-tsx": () => import("./../../../src/pages/prijzen.tsx" /* webpackChunkName: "component---src-pages-prijzen-tsx" */),
  "component---src-pages-wettelijke-info-tsx": () => import("./../../../src/pages/wettelijke-info.tsx" /* webpackChunkName: "component---src-pages-wettelijke-info-tsx" */)
}

